<!-- 微信里领取红包页面-->
<template>
  <div class="body">
    <img style="width: 100vw;height: 182.933vw;"
         src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/3297003b-031b-4510-a3e9-912d6dfce2c9.png"
         alt="">
    <div style="position: absolute;top: var(--490);">
      <img style="width: var(--686);height: var(--12);"
           src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/83f23ef5-501c-438a-a99e-a303836739ec.png">

      <div class="inputKouLing margin-leftAuto margin-rightAuto margin-top30 margin-bottom30">
        <div>
          <van-field v-model="redPackKouLing"
                     placeholder="输口令，抢红包" clearable
                     placeholder-class='placeholder-input'/>
        </div>
      </div>
      <img style="width: var(--686);height: var(--12);"
           src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/83f23ef5-501c-438a-a99e-a303836739ec.png">

    </div>
    <div @click="userGetRedPacket"
         class="sendRedPackClass positionAbsolute font-color1B1B1B
         font-size32 font-weightBold flex_center margin-rightAuto margin-leftAuto"
         style="bottom: var(--120);">
      抢红包
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant';
import {Api} from "@/utils/net/Api";
import EncryptUtils from "@/utils/EncryptUtils";

export default {
  data() {
    return {
      //总红包标识uuid
      totalRedPacketUuid: "",
      redPackKouLing: "",
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "领红包"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "true",
        statusBarBgColor: "#00000000",
        showStatusBar: "true"
      }));
      window.Android.setTitleStyle(JSON.stringify({titleBarBackgroundColor: '#ffffff', textColor: '#1B1B1B'}));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    //针对新版参数
    if (!EncryptUtils.isEmpty(this.$route.query.d)) {
      this.totalRedPacketUuid = this.$route.query.d;
    }
  },
  mounted() {
  },
  methods: {
    // 领取红包
    userGetRedPacket() {
      let that = this;
      if (EncryptUtils.isEmpty(this.redPackKouLing)) {
        Toast("请输入口令")
        return
      }
      let param = {
        uuid: this.totalRedPacketUuid,
        keycode: this.redPackKouLing,
      }
      Api.userGetRedPacketByLoginKeycode(param).then(res => {
        if (res.code === 100) {
          console.log(res.data)
          Toast("领取成功")
          //领取成功后跳转到红包详情页
          that.skipProjectRedReceivePage()
        } else {
          Toast(res.msg);
        }
      })
    },
    // 查看红包详情
    skipProjectRedReceivePage() {
      let that = this;
      this.$router.push({
        path: '/projectRedPackUserRecordDetail',
        query: {
          totalRedPacketUuid: that.totalRedPacketUuid,
        }
      });
    },
  }
}
</script>

<style scoped>
.body {
  background-color: #E4514F;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}

.sendRedPackButton {
  font-size: var(--36);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: var(--48);
  position: absolute;
  top: 141.866vw;
  width: var(--526);
  height: var(--104);
  border-radius: var(--52);
  background: linear-gradient(90deg, #FFC652 0%, #FF8307 100%);
}

.gongXiFaCai {
  position: absolute;
  top: var(--292);
  font-size: var(--40);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #FFF3C6;
  line-height: var(--56);
  padding-left: var(--30);
  padding-right: var(--30);
  width: 100%;
}

.fromText {
  margin-left: var(--26);
  font-size: var(--40);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #FFF3C6;
  line-height: var(--42);
}

.inputKouLing {
  padding-right: var(--30);
  padding-left: var(--30);
  width: var(--632);
  height: var(--108);
  background: #FFFFFF;
  border-radius: var(--10);
}


/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
  flex: 1;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #999999;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--108);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1b1b1b;
  font-size: var(--30);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  border-bottom: none;
}

.sendRedPackClass {
  width: var(--618);
  height: var(--104);
  background: #FFFFFF;
  border-radius: var(--10);
}
</style>
